import {
  GetAttachmentFilesByMemoId,
  GetRefDocFormTable,
} from "../Services/MemoService";

export type RefResult = {
  items: any[];
  listRefDocsDetail: any[];
  listFileAttachDetails?: any[] | null;
  errorMessage?: string;
};

export const onChanceRef = async (
  refTempSelected: any,
  memoDetail: any,
  listFormNames: any,
  refAttribute: any,
  currentTemp: any,
  _onControlChange: (
    controlTemplate: any,
    controlValue: any,
    isInTable: boolean,
    isRef: boolean
  ) => void
) => {
  let result: RefResult = {
    items: [],
    listRefDocsDetail: [],
    listFileAttachDetails: null,
  };

  try {
    if (memoDetail) {
      if (
        refTempSelected !== undefined &&
        refTempSelected !== null &&
        refTempSelected.length > 0
      ) {
        let template_desc = currentTemp;
        let _listRefDocsDetail: any[] = [];
        let listFileAttachDetails: any[] = [];
        let docsCode: any[] = [];
        let refTemp: any;
        const refColumn = JSON.parse(listFormNames?.RefDocColumn);
        if (
          listFormNames?.RefTemplate !== "" &&
          listFormNames?.RefTemplate !== null
        ) {
          refTemp = JSON.parse(listFormNames?.RefTemplate);
          refTemp.map((temp: any) => {
            if (temp.DocumentCode !== "") {
              docsCode.push(temp.DocumentCode);
            }
          });
        }

        for (const refTems of refTempSelected) {
          let refDocDetail = {
            memoRefdoc_id: refTems.MemoId,
            doc_no: refTems.DocumentNo,
            template_ID: listFormNames?.template_id,
            template_Name: listFormNames?.template_name,
            memoSubject: listFormNames?.subject,
          };
          if (refTems.MAdvancveForm) {
            const selectedColumn = JSON.parse(refTems?.MAdvancveForm);
            let valibCol: any[] = [];
            _listRefDocsDetail.push(refDocDetail);

            refColumn.map((col: any) => {
              if (col.Value !== null && col.Value !== "") {
                if (col.Value === "_DocumentNo") {
                  col.ControlValue = { value: refTems.DocumentNo };
                } else if (col.Value === "_DocumentAmount") {
                  col.ControlValue = { value: refTems.Amount };
                } else if (col.Value === "_RequestorEmployeeName") {
                  col.ControlValue = { value: refTems.RNameEn };
                } else if (col.Value === "_RequestorEmployeePositionTh") {
                  col.ControlValue = { value: refTems.RPositionTh };
                } else if (col.Value === "_RequestorEmployeePositionEn") {
                  col.ControlValue = { value: refTems.RPositionEn };
                } else if (col.Value === "_RequestorEmployeeDepartmentTh") {
                  col.ControlValue = { value: refTems.DepartmentTh };
                } else if (col.Value === "_RequestorEmployeeDepartmentEn") {
                  col.ControlValue = { value: refTems.DepartmentEn };
                } else if (col.Value === "_InformationFormName") {
                  col.ControlValue = { value: refTems.TemplateName };
                } else if (col.Value === "_InformationRequestDate") {
                  col.ControlValue = { value: refTems.RequestDate };
                } else if (col.Value === "_InformationCompany") {
                  col.ControlValue = { value: refTems.CompanyName };
                } else {
                  let selColLabel = "";
                  if (col.Value.indexOf("_") !== -1) {
                    let colLabel = col.Value.split("_");
                    selColLabel = colLabel.pop();
                  } else {
                    selColLabel = col.Value;
                  }
                  if (col.TypeControl === "Table") {
                    let sourceRow: any = -1;
                    let sourceCols: any[] = [];
                    let targetPositions: any[] = [];
                    let originDefaultValue = "";
                    selectedColumn.items.map((selCol: any, rowIdx: number) => {
                      selCol.layout.map((_layout: any) => {
                        if (
                          selColLabel === _layout.template.label &&
                          _layout.template.type === "tb"
                        ) {
                          col.objTable?.map(
                            (objTable: any, tarColIdx: number) => {
                              if (objTable?.Value !== null) {
                                _layout.template.attribute.column.map(
                                  (refTable: any, colIdx: number) => {
                                    if (refTable.label === objTable.Value) {
                                      // originDefaultValue =
                                      sourceRow = rowIdx;
                                      sourceCols.push({
                                        colIdx: colIdx,
                                      });
                                      targetPositions.push(tarColIdx);
                                    }
                                  }
                                );
                              }
                            }
                          );
                        }
                      });
                    });
                    if (sourceRow > -1) {
                      let tableRows: any[] = [];
                      if (refAttribute?.mode !== "Single") {
                        //adding if condition to check value of colcontrol
                        if (col.ControlValue) {
                          tableRows = col.ControlValue;
                        }
                      }
                      selectedColumn.items[sourceRow].layout[0]?.data?.row?.map(
                        (row: any) => {
                          let newRow: any[] = [];

                          for (let i = 0; i < col.objTable.length; i++) {
                            newRow.push({ value: null });
                          }

                          sourceCols.map((col: any, colIdx: number) => {
                            newRow[targetPositions[colIdx]] = row[col.colIdx];
                          });
                          if (newRow.length > 0) {
                            tableRows.push(newRow);
                          }
                        }
                      );
                      col.ControlValue = tableRows;
                    }
                  } else {
                    selectedColumn.items.map((selCol: any) => {
                      selCol.layout.map((_layout: any) => {
                        if (_layout.template.label === selColLabel) {
                          col.ControlValue = _layout.data;
                        }
                      });
                    });
                  }
                }
                valibCol.push(col);
              }
            });

            template_desc.map((item: any, rowIdx: number) => {
              item.layout.map((_layout: any, colIdx: number) => {
                valibCol.map((col: any) => {
                  if (!col.ControlValue) return;

                  if (
                    col.TypeControl === "Table" &&
                    _layout.template.type === "tb"
                  ) {
                    if (col.Key === _layout.template.label) {
                      if (
                        JSON.stringify(_layout.data.row) !==
                        JSON.stringify(col.ControlValue)
                      ) {
                        _layout.template.attribute.column.forEach(
                          (originCol: any, _colIdx: number) => {
                            col.ControlValue.map((refCol: any) => {
                              if (refCol[_colIdx]?.value === null) {
                                if (
                                  originCol.control.template.attribute
                                    .default !== ""
                                ) {
                                  refCol[_colIdx].value =
                                    originCol.control.template.attribute.default;
                                }
                              }
                            });
                          }
                        );

                        const rowstateCol =
                          _layout.template.attribute.column.find(
                            (col: any) => col.label === "rowstate"
                          );
                        if (rowstateCol) {
                          col.ControlValue.map((e: any) => {
                            e.push({ value: null });
                            return e;
                          });
                        }

                        _layout.data.row = [...col.ControlValue];
                      }
                    }
                  } else if (
                    col.TypeControl != "Table" &&
                    _layout.template.type != "l"
                  ) {
                    if (col.Key === _layout.template.label) {
                      if (_layout.data?.value !== col.ControlValue?.value) {
                        _layout.data = col.ControlValue;
                      }
                    }
                  } else {
                    // Nothing do something because template type is heading
                  }
                });
              });
            });
            if (
              listFormNames.RefDocDisplay.split(",")[5] &&
              listFormNames.RefDocDisplay.split(",")[5] === "Yes"
            ) {
              let _res: any[] = [];
              await getMemoForAttch(refTems.MemoId).then((e: any) => {
                if (e) {
                  result.listFileAttachDetails = [
                    ...listFileAttachDetails,
                    ...e,
                  ];
                }
              });
            }
          }

          result.items = template_desc;
          result.listRefDocsDetail = _listRefDocsDetail;
        }
      }
    }

    return result;
  } catch (error: any) {
    console.error("ref=>error", error);

    result.errorMessage = error;
    return result;
  }
};

const getMemoForAttch = async (memoId: number) => {
  const requestBody = {
    memoid: memoId,
  };
  let res: any[] = [];
  await GetAttachmentFilesByMemoId(requestBody).then((e: any) => {
    res = e;
  });
  return res;
};

export const fetchRefDocFormTable = async (memoDetail: any, template: any) => {
  let tempAtt = template.attribute;
  if (tempAtt !== undefined) {
    if (tempAtt.refdoc !== undefined) {
      const dataRequest = {
        PageIndex: 0,
        PageSize: 0,
        CUserID: memoDetail.creator.EmployeeId.toString(),
        RUserID: memoDetail.requestor.EmployeeId.toString(),
        ConditionRefdoc:
          tempAtt.conditionrefdoc.length > 0
            ? JSON.stringify(tempAtt.conditionrefdoc)
            : "",
        Search: "",
        docDataSource: tempAtt.refdoc.docDataSource,
        docReport: tempAtt.refdoc.docReport,
        docCancelDoc: tempAtt.refdoc.docCancelDoc,
        docEditDoc: tempAtt.refdoc.docEditDoc,
        docNewDoc: tempAtt.refdoc.docNewDoc,
        doccontrol: tempAtt.refdoc.doccontrol !== "N",
        docref: tempAtt.refdoc.docref,
      };

      const refDetails = await GetRefDocFormTable(dataRequest);

      if (refDetails) {
        if (refDetails?.dt_Report?.length > 0) {
          return refDetails.dt_Report;
        } else {
          return [];
        }
      }
    }
  }
};

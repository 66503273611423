import { IListApprovalDetailsModel } from "../IRequestModel/IListApprovalDetailsModel";

export const GetApprovalsByMemoIDs = async (memoIds: number[]) => {
  const respone = await fetch("api/LineApprove/GetByMemoIds", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ memoIds: memoIds }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("service=>error", err);
    });
  return respone;
};

export const SaveLineApprovalsByMemoId = async (
  memoId: number,
  current_approval_level: number,
  lineApprovals: {
    LineId: number;
    MemoId: number;
    Sequence: number;
    Emp_id: number;
    Signature_Id: number;
    ColumnJsonCondValue: string;
  }[]
) => {
  const respone = await fetch("api/LineApprove/SaveLineApprove", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      MemoId: memoId,
      current_approval_level: current_approval_level,
      ListApprovalDetails: lineApprovals,
    }),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      console.log("service=>error", err);
    });
  return respone;
};

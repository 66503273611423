import { Button, Divider, Form, Input, Select, Space } from "antd";
import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import DataSourceConditionRow from "./DataSourceConditionRow";
import { LogicFormProps } from "../JsonLogicForm";

const DataSourceLoadForm = ({ form, advanceFormItem }: LogicFormProps) => {
  const controlOption: any[] = advanceFormItem
    .filter(
      (e) =>
        e.type === "dd" || e.type === "cb" || e.type === "r" || e.type === "tb"
    )
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: "Tb :" + item.label,
          options: item.columns
            .filter((e) => {
              return e.type === "dd" || e.type === "cb" || e.type === "r";
            })
            .map((col) => ({
              key: item.label + col.label,
              label: col.label,
              value: col.label,
            })),
        };
      }
      return {
        label: item.label,
        value: item.label,
      };
    })
    .filter((e) => e.label);

  return (
    <>
      <Form.Item label="Action control" name="label">
        <Select
          placeholder="Action control"
          style={{ minWidth: 200 }}
          showSearch
          options={controlOption}
        ></Select>
      </Form.Item>
      <Form.Item label="Table" name="table">
        <Input placeholder="Table" />
      </Form.Item>
      <Form.Item label="Label action field" name="labelactionfield">
        <Input placeholder="Label action field" />
      </Form.Item>
      <Divider>Related fields</Divider>
      <Form.List name="fields">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  key={idx}
                  size={"middle"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  align="start"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "field"]}
                    rules={[{ required: true, message: "Missing field" }]}
                  >
                    <Input placeholder="Field" />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add field
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <Divider>Conditions</Divider>
      <Form.List name="conditions">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ ...restField }, idx) => (
                <DataSourceConditionRow
                  {...restField}
                  remove={remove}
                  onRowChange={(rowData) => {
                    const value = form.getFieldsValue();
                    value.conditions = value.conditions.map((e, idx) => {
                      if (rowData[idx]) {
                        return rowData[idx];
                      }
                      return e;
                    });
                    form.setFieldsValue(value);
                  }}
                  defaultValue={form.getFieldValue("conditions")[idx]}
                />
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add condition
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

export default DataSourceLoadForm;

import { Checkbox } from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FieldValues,
  UseFormGetValues,
  get,
} from "react-hook-form";
import { CheckboxCpmponents } from "../../CheckboxCpmponents/CheckboxCpmponents";
import { InputTextComponents } from "../../InputTextComponents/InputTextComponents";
import { Dropdown } from "primereact/dropdown";
import CalendarDefaultValueGroup from "./CalendarDefaultValueGroup";
import useMasterSetting from "../../../hooks/useMasterSetting";

interface Props {
  control: any;
  errors: any;
  template: any;
  getValues: UseFormGetValues<FieldValues>;
  fromRender?: string;
}

export default function CalendarComponent(props: Props) {
  const { masterSetting, getMasterSetting } = useMasterSetting();

  const dataRequest = {
    label: props.template.label || "",
    alter: props.template.alter || "",
    description: props.template.attribute?.description || "",
    symbol: props.template.attribute?.date?.symbol || "",
    require: props.template.attribute?.require === "Y" ? true : false,
    readonly: props.template.attribute?.readonly === "Y" ? true : false,
    widthInTable: parseInt(props.template.attribute?.widthInTable) || 0,
    hideInPdf: props.template.attribute?.hideInPdf === "Y" || "" ? true : false,
    default: props.template.attribute?.default || "",
    defaultType: props.template.attribute?.defaultType || "None",
    displayFormat: props.template.attribute?.displayFormat,
  };

  const [disabled, setDisabled] = useState<boolean>(
    props.template.attribute?.defaultType === "None"
  );
  return (
    <div className="container">
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Label</p>
          <span style={{ color: "red" }}>*</span>
          <span className="headtext-form-requestor"> :</span>
        </div>
        <div className="col-md-10">
          <Controller
            name="label"
            control={props.control}
            defaultValue={dataRequest.label}
            rules={{ required: "label is required." }}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                autoFocus
                className={`set-input-component-css ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </div>
      </div>
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Alt Label: </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="alter"
            control={props.control}
            defaultValue={dataRequest.alter}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                className={`set-input-component-css ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </div>
      </div>
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Description: </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="description"
            control={props.control}
            defaultValue={dataRequest.description}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                className={`set-input-component-css ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </div>
      </div>
      {props.fromRender === "table" && (
        <div className="row set-margin-in-row-add-control">
          <div className="col-md-2 set-layout-text-input">
            <p className="headtext-form-requestor">Width: </p>
          </div>
          <div className="col-md-10">
            <div className="p-inputgroup set-height-input">
              <Controller
                name="widthInTable"
                control={props.control}
                defaultValue={dataRequest.widthInTable}
                render={({ field, fieldState }) => (
                  <InputNumber
                    inputId="minmax"
                    value={field.value}
                    onValueChange={(e) => field.onChange(e.value)}
                    mode="decimal"
                    min={0}
                    max={100}
                    className={`set-input-component-css ${classNames({
                      "p-invalid": fieldState.invalid,
                    })}`}
                  />
                )}
              />
              <span className="p-inputgroup-addon set-font-pixel">%</span>
            </div>
          </div>
        </div>
      )}
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Symbol : </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="symbol"
            defaultValue={dataRequest.symbol}
            control={props.control}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                className={`set-input-component-css ${classNames({
                  "p-invalid": fieldState.invalid,
                })}`}
              />
            )}
          />
        </div>
      </div>
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Required: </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="require"
            control={props.control}
            defaultValue={dataRequest.require}
            render={({ field, fieldState }) => (
              <div className={"set-layout-check-box-create-control"}>
                <>
                  <Checkbox
                    className="set-css-checkbox-in-create-control"
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                    checked={field.value}
                  />
                  <span className="set-text-check-box-create-control">Yes</span>
                </>
              </div>
            )}
          />
        </div>
      </div>
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Readonly: </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="readonly"
            control={props.control}
            defaultValue={dataRequest.readonly}
            render={({ field, fieldState }) => (
              <div className={"set-layout-check-box-create-control"}>
                <>
                  <Checkbox
                    className="set-css-checkbox-in-create-control"
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                    checked={field.value}
                  />
                  <span className="set-text-check-box-create-control">Yes</span>
                </>
              </div>
            )}
          />
        </div>
      </div>
      {/* {props.fromRender === "table" && ( */}
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">HideInPdf: </p>
        </div>
        <div className="col-md-10">
          <Controller
            name="hideInPdf"
            control={props.control}
            defaultValue={dataRequest.hideInPdf}
            render={({ field, fieldState }) => (
              <div className={"set-layout-check-box-create-control"}>
                <>
                  <Checkbox
                    className="set-css-checkbox-in-create-control"
                    inputId={field.name}
                    onChange={(e) => {
                      field.onChange(e.checked);
                    }}
                    checked={field.value}
                  />
                  <span className="set-text-check-box-create-control">Yes</span>
                </>
              </div>
            )}
          />
        </div>
      </div>
      {/* )} */}
      {/* default calendar */}
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Default Value: </p>
        </div>
        <div className="col-md-4">
          <div className="p-inputgroup">
            <Controller
              name="defaultType"
              control={props.control}
              defaultValue={dataRequest.defaultType}
              render={({ field, fieldState }) => (
                <div className={"set-layout-check-box-create-control"}>
                  <Dropdown
                    {...field}
                    onChange={(e) => {
                      if (e.value !== "None") {
                        setDisabled(false);
                      } else {
                        setDisabled(true);
                      }
                      field.onChange(e.value);
                    }}
                    options={[
                      { name: "None", value: "None" },
                      // { name: "Calendar", value: "Calendar" },
                      { name: "Days", value: "Days" },
                      // { name: "Months", value: "Months" },
                      // { name: "Year", value: "Year" },
                    ]}
                    optionLabel="name"
                  />
                </div>
              )}
            />
            <Controller
              name="default"
              control={props.control}
              defaultValue={dataRequest.default}
              render={({ field, fieldState }) => (
                <div className={"set-layout-check-box-create-control"}>
                  <CalendarDefaultValueGroup
                    field={field}
                    disabled={disabled}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className="row set-margin-in-row-add-control">
        <div className="col-md-2 set-layout-text-input">
          <p className="headtext-form-requestor">Display Format: </p>
        </div>
        <div className="col-md-4">
          <div className="p-inputgroup">
            <Controller
              name="displayFormat"
              control={props.control}
              defaultValue={dataRequest.displayFormat}
              render={({ field, fieldState }) => (
                <div className={"set-layout-check-box-create-control"}>
                  <InputText
                    {...field}
                    placeholder={masterSetting?.FormatDate?.Value1}
                  />
                </div>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { Button, Checkbox, Divider, Form, Input, Select, Space } from "antd";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { getScreenWidthWithBreakpoint } from "../../../../Helper/ScreenHelper";
import ConditionRow from "./ConditionRow";
import { LogicFormProps } from "../JsonLogicForm";
import { ButtonComponents } from "../../../ButtonComponents/ButtonComponents";
import ShowHideControlModal from "./ShowHideControlModal";
import TextArea from "antd/lib/input/TextArea";
const { Option, OptGroup } = Select;

const HideAndReadOnlyControlForm = ({
  advanceFormItem,
  form,
}: LogicFormProps) => {
  const [breakpoint, setBreakpoint] = useState(getScreenWidthWithBreakpoint());
  const [selectedDataIdx, setSelectedDataIdx] = useState<number>(-1);

  const controlOption: any[] = advanceFormItem
    .map((item) => {
      if (item.type === "tb" && item.columns) {
        return {
          label: item.label,
          value: JSON.stringify({
            label: item.label,
            type: item.type,
          }),
          options: item.columns.map((col) => ({
            label: col.label,
            value: JSON.stringify({
              label: col.label,
              type: col.type,
              isInTable: item.label,
            }),
          })),
        };
      }
      return {
        label: item.label,
        value: JSON.stringify({
          label: item.label,
          type: item.type,
        }),
      };
    })
    .filter((e) => e.label);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(getScreenWidthWithBreakpoint());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Responsive layout
  const isSmallScreen = breakpoint === "xs" || breakpoint === "sm";

  return (
    <>
      <Form.Item label="Action control" name="label">
        <Select
          placeholder="Action with control"
          style={{ minWidth: 200 }}
          showSearch
        >
          {controlOption.map((item: any, index) => {
            if (item.options) {
              return (
                <>
                  <Option key={item.label} value={item.value}>
                    {item.label}
                  </Option>
                  <OptGroup label={item.label + "'s columns"}>
                    {item.options.map((option: any) => (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    ))}
                  </OptGroup>
                </>
              );
            } else {
              return (
                <Option key={item.label} value={item.value}>
                  {item.label}
                </Option>
              );
            }
          })}
        </Select>
      </Form.Item>
      <Form.Item
        label="Reset on action"
        name="resetonaction"
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Divider>Conditions</Divider>
      <Form.List name="conditions">
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, idx) => (
                <Space
                  align="center"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: 20,
                  }}
                >
                  <Form.Item name={[name, "conditions"]} noStyle>
                    <ButtonComponents
                      key={"con" + idx}
                      setStyleProps={{
                        width: "150px",
                        borderRadius: "6px",
                        boxShadow: "none",
                        border: "1px solid #282f6a",
                        fontSize: "13px",
                        paddingLeft: "16px",
                      }}
                      typeProps={"button"}
                      setClassNameProps={"p-button-text-position"}
                      setLabelProps={"Edit Conditions"}
                      onClickProps={() => {
                        setSelectedDataIdx(idx);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={[name, "description"]}
                    noStyle
                    validateTrigger={["onChange", "onBlur"]}
                  >
                    <TextArea placeholder="Description" cols={50} />
                  </Form.Item>
                  <MinusCircleOutlined
                    onClick={() => remove(name)}
                    rev={undefined}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    setSelectedDataIdx(-1);
                    add();
                  }}
                  block
                  icon={<PlusOutlined rev={undefined} />}
                >
                  Add condition
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
      <ShowHideControlModal
        advanceFormItem={advanceFormItem}
        rowData={
          selectedDataIdx !== -1
            ? form.getFieldValue("conditions")[selectedDataIdx] ?? {
                conditions: [],
              }
            : undefined
        }
        onSubmit={(formData: any) => {
          if (formData) {
            let allField: any = form.getFieldsValue();

            allField["conditions"][selectedDataIdx].conditions =
              formData.conditions;

            form.setFieldsValue(allField);
          }
          setSelectedDataIdx(-1);
        }}
        form={form}
      />
    </>
  );
};

export default HideAndReadOnlyControlForm;

import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import ComponentLabel from "../../ControlComponents/ComponentLabel";
import { Col, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { TableInputProps } from "../TableComponent/TableComponent";
import { ILogicRequestBody } from "../../../IRequestModel/ILogicModel";
import { dataReleteToDD } from "../../../Helper/RequestScreenHelper";
import { formatKeyLogicData } from "../../../Helper/formatKeyLogicData";

type InputProps = {
  template: any;
  data: any;
  rowIdx: number;
  colIdx: number;
  col?: any;
  colText?: number;
  colAction?: number;
  // statusMemoDetail?: boolean;
  name: string;
  control: any;
  canEditDoc: boolean;
  checkActionPage: string;
  buttonType: string;
  actionAlert?: any;
  onControlChange: (controlTemplate: any, controlValue: any) => any;
};

type SelectCheckboxProps = {
  ref?: any;
  template: any;
  value?: any;
  status?: any;
  onChange: (value: any, type: any) => void;
  canEditDoc: boolean;
  checkActionPage: string;
  onDropdownVisibleChange?: (open: boolean) => void;
  tableOptions?: any[];
  loading?: boolean;
  actionAlert?: any;
};
const SelectCheckboxInTableComponent: React.FC<SelectCheckboxProps> = ({
  ref,
  template,
  loading,
  value,
  status,
  onChange,
  canEditDoc,
  actionAlert,
  onDropdownVisibleChange,
  checkActionPage,
  tableOptions,
}) => {
  const { Option } = Select;

  const [action, setAction] = useState<boolean>(false);
  // console.log("actionAlertSelect=>", actionAlert);
  // console.log("template=>", template);
  // console.log("value=>", value);

  useEffect(() => {
    checkStatus();
  }, [actionAlert]);

  const onSelectChange = (value: string[]) => {
    let items = tableOptions || template.attribute.items;
    let test: any[] = [];

    const res = items.map((item: any, idx: number) => {
      let check = "N";

      for (let i = 0; i < value.length; i++) {
        const _value = value[i];

        if (_value === item.item) {
          check = "Y";
          test.push(item.item);
        }
      }
      return check;
    });

    onChange(test.toString(), "cb");
  };

  const formatValue = (value: any) => {
    let items = template.attribute.items;

    let _res: any[] = [];
    if (value && value.length > 0) {
      const _values = value.split(",");
      for (let i = 0; i < items.length; i++) {
        const item: any = items[i];
        if (value[i] === "Y") {
          _res.push(item.item);
        }
      }
      return _values;
    }
    return [];
  };

  const checkStatus = () => {
    if (actionAlert) {
      if (actionAlert.Data) {
        actionAlert.Data.map((data: any) => {
          let split = value.split(",");
          split.map((splitData: any) => {
            if (
              actionAlert.ErrorType === "SpecialError" &&
              data.Label === template.label &&
              data.Value === splitData
            ) {
              setAction(true);
            }
          });
        });
      }
    }
  };

  // console.log("actionAlert=>", actionAlert);
  return (
    <>
      <Select
        // ref={ref}
        size={"large"}
        showSearch
        value={formatValue(value)}
        placeholder={template.attribute.description}
        optionFilterProp="children"
        onChange={onSelectChange}
        loading={loading}
        filterOption={(input, option) => {
          console.log({ option });
          return (option!.children! as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        disabled={!canEditDoc || template.attribute.readonly === "Y"}
        status={action ? "error" : ""}
        mode="tags"
        onDropdownVisibleChange={onDropdownVisibleChange}
      >
        {tableOptions
          ? tableOptions?.map((option: any, idx: number) => (
              <Option value={option.item}>{option.item}</Option>
            ))
          : template.attribute?.items?.map((option: any, idx: number) => (
              <Option value={option.item}>{option.item}</Option>
            ))}
      </Select>
    </>
  );
};
const SelectCheckboxComponent: React.FC<SelectCheckboxProps> = ({
  ref,
  template,
  value,
  status,
  onChange,
  canEditDoc,
  checkActionPage,
}) => {
  const { Option } = Select;

  const onSelectChange = (value: string[]) => {
    let items = template.attribute.items;
    let test: any[] = [];
    const res = items.map((item: any, idx: number) => {
      let check = "N";

      for (let i = 0; i < value.length; i++) {
        const _value = value[i];

        if (_value === item.item) {
          check = "Y";
          test.push(item.item);
        }
      }
      return check;
    });

    // for (let i = 0; i < res.length; i++) {
    //   const _res = res[i];
    //   if (_res === "Y") {
    //     _value.push(i);
    //   }
    // }
    onChange(
      {
        item: res,
        value: test.toString(),
      },
      "cb"
    );
  };

  const formatValue = (value: any) => {
    let _res: any[] = [];

    if (value && value.item) {
      if (value.value && typeof value.value == "string") {
        let namesArray = value.value.replace(/,$|,\s+$/, "").split(",");
        _res = namesArray;
      }

      return _res;
    }

    return [];
  };

  return (
    <>
      <Select
        // ref={ref}
        size={"large"}
        showSearch
        value={formatValue(value)}
        placeholder={template.attribute.description}
        optionFilterProp="children"
        onChange={onSelectChange}
        filterOption={(input, option) => {
          return (option!.children! as unknown as string)
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
        disabled={!canEditDoc || template.attribute.readonly === "Y"}
        status={status}
        mode="tags"
      >
        {template.attribute.items.map((option: any) => (
          <Option value={option.item}>{option.item}</Option>
        ))}
      </Select>
    </>
  );
};

export const SelectCheckboxControlComponent: React.FC<InputProps> = ({
  colIdx,
  rowIdx,
  control,
  name,
  data,
  template,
  col,
  colAction,
  colText,
  canEditDoc,
  checkActionPage,
  buttonType,
  actionAlert,
  onControlChange,
  ...props
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required:
          canEditDoc &&
          template.attribute.require !== "" &&
          template.attribute.require === "Y" &&
          buttonType !== "draft" &&
          buttonType !== "cancel" &&
          buttonType !== "reject" &&
          buttonType !== "recall" &&
          buttonType !== "assign"
            ? true
            : false,
      }}
      render={({
        field: { onChange, value, onBlur, name, ref },
        formState: { errors, isSubmitted },
      }) => (
        <>
          <ComponentLabel
            col={col}
            colText={colText}
            rowIdx={rowIdx}
            colIdx={rowIdx}
            template={template}
          />
          <Col
            sm={col === undefined ? 12 : 12}
            md={col === undefined ? colAction : 12}
            xs={col === undefined ? 12 : 12}
            xl={col === undefined ? colAction : 12}
            className={"padding-controller"}
          >
            <div
              className={`input-component-container  ${
                isSubmitted &&
                errors?.items &&
                errors?.items[rowIdx] &&
                errors?.items[rowIdx].layout[colIdx]
                  ? "set-layout-required"
                  : ""
              }`}
            >
              <SelectCheckboxComponent
                {...{ canEditDoc, checkActionPage }}
                template={template}
                onChange={(value) => {
                  onChange({
                    ...value,
                    data: {
                      value,
                    },
                  });
                  onControlChange(template, value.value);
                }}
                value={value}
                status={
                  isSubmitted &&
                  ((errors?.items &&
                    errors?.items[rowIdx] &&
                    errors?.items[rowIdx].layout[colIdx]) ||
                    (value?.item?.every((_value: string) => _value === "N") &&
                      template.attribute.require === "Y" &&
                      buttonType !== "draft")) &&
                  "error"
                }
                actionAlert={actionAlert}
              />

              {isSubmitted &&
                ((errors?.items &&
                  errors?.items[rowIdx] &&
                  errors?.items[rowIdx].layout[colIdx]) ||
                  (value?.item?.every((_value: string) => _value === "N") &&
                    template.attribute.require === "Y" &&
                    buttonType !== "draft")) && (
                  <small id="Name-help" className="p-error p-d-block">
                    {template.label} is required.
                  </small>
                )}
            </div>
          </Col>
        </>
      )}
    />
  );
};

export const SelectCheckboxTableComponent: React.FC<TableInputProps> = ({
  name,
  template,
  saveFunc,
  inputRef,
  isEditing,
  onEdit,
  children,
  canEditDoc,
  checkActionPage,
  actionAlert,
  allLogicInTable,
  record,
  ...props
}) => {
  const [customOptions, setCustomOptions] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  const logicRequest = () => {
    if (allLogicInTable) {
      let conditions: ILogicRequestBody[] = allLogicInTable.filter(
        (e: any) => e.actionlabel === template.label
      );
      if (conditions.length > 0) {
        setLoading(true);

        dataReleteToDD(
          conditions.map((con) => {
            if (con.Key !== "") {
              con.Value = record[con.Key];
            }
            return con;
          })
        ).then((val) => {
          const res = formatKeyLogicData({ data: val });
          if (res) {
            setCustomOptions([...res.data]);
          }
          setLoading(false);
        });
      }
    }
  };

  if (!isEditing) {
    return (
      <div style={{ paddingRight: 24 }} onMouseOver={onEdit}>
        <SelectCheckboxInTableComponent
          {...{ canEditDoc, checkActionPage }}
          ref={inputRef}
          value={children[1]}
          template={template}
          onChange={saveFunc}
          actionAlert={actionAlert}
        />
      </div>
    );
  }
  return (
    <>
      <Form.Item
        style={{ margin: 0 }}
        name={name}
        rules={
          canEditDoc && [
            {
              required:
                template.attribute.require === "Y" && checkActionPage === "edit"
                  ? true
                  : false,
              message: `${template.label} is required.`,
            },
          ]
        }
      >
        <SelectCheckboxInTableComponent
          {...{ canEditDoc, checkActionPage }}
          ref={inputRef}
          template={template}
          onChange={saveFunc}
          tableOptions={customOptions}
          actionAlert={actionAlert}
          loading={loading}
          onDropdownVisibleChange={(open) => {
            if (open) {
              logicRequest();
            } else {
              setCustomOptions(undefined);
              setLoading(false);
            }
          }}
        />
      </Form.Item>
    </>
  );
};
